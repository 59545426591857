
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { withScope } from '@sentry/nextjs';
import { useGetNaumaiPageCollectionBySlugQuery } from 'content-service';
import { GetStaticProps } from 'next';
import { ROUTES } from 'src/constants';
import { naumaiPageQuery, NaumaiPageResult } from 'src/serversideQueries/naumaiPageQuery';
import StandardPage from 'src/templates/StandardPage';
import { useTrackPageViews } from 'tracking';
import { Error as ErrorComponent } from 'ui';
import { buildPageQueryInput } from 'utils/src/helpers/slug';
const Home = ({ slug, preview, locale, pageSeo }: NaumaiPageResult) => {
    const { data, error } = useGetNaumaiPageCollectionBySlugQuery({
        variables: {
            ...buildPageQueryInput(slug ?? ''),
            preview,
            locale
        }
    });
    const [page] = data?.swnz_naumaiPageStandardCollection?.items ?? [];
    useTrackPageViews({ pageName: pageSeo?.title ?? '', pageViewData: undefined });
    if (error || !page) {
        withScope(function (scope) {
            // scope.setExtras({ path: pathArray, error, page })
            // captureException(new Error(`Slug Page Error slug ${pathArray.toString()}`))
        });
        return <ErrorComponent preview={preview} title={slug} statusCode={404}/>;
    }
    return (<>
      {page?.__typename === 'SWNZ_NaumaiPageStandard' && (<StandardPage content={page} pageSeo={pageSeo}/>)}
    </>);
};
export default Home;
const getStaticProps: GetStaticProps = async ({ preview = false }) => {
    const slug = ROUTES.HOME;
    // const slug = SITE_CONFIG.SITE_HOMEPAGE_CONTENT_SERVICE_SLUG
    try {
        return await naumaiPageQuery({ preview, slug });
    }
    catch (error) {
        console.error(`  Error executing queries in home page`);
        return {
            props: { preview }
        };
    }
};

    async function __Next_Translate__getStaticProps__191ba3683a2__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ba3683a2__ as getStaticProps }
  