import { Box } from '@chakra-ui/react'
import {
  Seo_Swnz_SeoFragment,
  StandardPageNaumai_Swnz_NaumaiPageStandardFragment,
} from 'content-service'
import dynamic from 'next/dynamic'
import { ReactElement } from 'react'
import Hero from 'src/components/Hero'
import { ElementSelect, getHeroIntroWrapperBgColor, HeroIntroWrapper, PageSeo } from 'ui'
import { createHeroImageProps, SectionCollectionId } from 'utils'

export interface StandardPageProps {
  content: StandardPageNaumai_Swnz_NaumaiPageStandardFragment | null
  pageSeo?: Seo_Swnz_SeoFragment
}

const StoryModal = dynamic(() => import('ui/src/components/StoryModal/StoryModal'))

function validateSWNZHero(content: StandardPageNaumai_Swnz_NaumaiPageStandardFragment) {
  if (content?.hero?.__typename === 'SWNZ_Hero') {
    return {
      ...content?.hero,
      textThemeColour: undefined,
    }
  }

  if (content?.hero?.__typename === 'SWNZ_HeroFeatured') {
    return {
      ...content?.hero,
      backgroundColour: undefined,
      displayType: undefined,
    }
  }

  return undefined
}

function IntroElement({
  intro,
  textColor,
}: {
  intro: SectionCollectionId
  textColor?: string | null
}) {
  return intro?.sys.id && intro?.__typename ? (
    <ElementSelect
      id={intro.sys?.id}
      typename={intro.__typename}
      isIntroElement={true}
      textColor={textColor}
    />
  ) : null
}

function PageElements({ elements }: { elements: SectionCollectionId[] }) {
  return (
    <>
      {elements.map((item, index) => {
        if (item?.sys.id && item?.__typename) {
          // prettier-ignore
          const { sys: { id }, __typename: typename } = item
          return <ElementSelect id={id} typename={typename} key={index} isIntroElement={false} />
        }
      })}
    </>
  )
}

const StandardPage = ({ content, pageSeo }: StandardPageProps): ReactElement | null => {
  if (content == null) {
    return null
  }

  const hero = validateSWNZHero(content)
  const heroBackgroundColor = getHeroIntroWrapperBgColor(
    hero?.__typename,
    hero?.displayType,
    hero?.backgroundColour,
    hero?.textThemeColour
  )

  const metaImgSrc = createHeroImageProps(hero?.mediaItem).src
  const elements = content.sectionsCollection?.items ?? []

  return (
    <Box as="main">
      {pageSeo ? <PageSeo pageSeo={pageSeo} metaImgSrc={metaImgSrc} /> : null}
      <HeroIntroWrapper backgroundColor={heroBackgroundColor}>
        <Hero hero={hero} />
        <IntroElement intro={content.intro} textColor={hero?.textThemeColour} />
      </HeroIntroWrapper>
      <PageElements elements={elements} />

      <StoryModal key={content.sys.id} />
    </Box>
  )
}

export default StandardPage
