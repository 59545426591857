import { useTheme } from '@chakra-ui/react'
import {
  HeroFeatured_Swnz_HeroFeaturedFragment,
  StandardPageNaumai_Swnz_NaumaiPageStandardFragment,
} from 'content-service'
import { HeroFeatured, HeroFeaturedProps, HeroSelect, OptimisedImage } from 'ui'
import {
  createCtaButton,
  createHeroFloatingCardProps,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG,
} from 'utils'
import { useMediaQuery } from 'utils/src/helpers'

type HeroFeatured = HeroFeatured_Swnz_HeroFeaturedFragment & {
  leadCaptureFormId?: string
}

const createHeroFeaturedComponentProps = (
  hero: HeroFeatured,
  isFocusedOnFaces?: boolean
): HeroFeaturedProps => ({
  heading: hero?.heading || '',
  subHeading: hero?.subHeading || '',
  introText: hero?.introText || '',
  primaryCta: createCtaButton(hero?.primaryCta),
  secondaryCta: createCtaButton(hero?.secondaryCta),
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FEATURED_HERO,
    objectPosition: '80%',
    loading: 'eager',
    blurOnLoad: false,
    onFocusBreakpoint: isFocusedOnFaces,
  }),
  heroFloatingCard: hero?.heroFloatingCard && createHeroFloatingCardProps(hero?.heroFloatingCard),
  leadCaptureFormId: hero?.leadCaptureFormId || '',
  textThemeColour: hero?.textThemeColour,
})

function Hero({ hero }: Pick<StandardPageNaumai_Swnz_NaumaiPageStandardFragment, 'hero'>) {
  const theme = useTheme()
  const isFocusedOnFaces = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  if (hero?.__typename === 'SWNZ_HeroFeatured') {
    return <HeroFeatured {...createHeroFeaturedComponentProps(hero, isFocusedOnFaces)} />
  }

  if (hero?.__typename === 'SWNZ_Hero') {
    return <HeroSelect displayType={hero?.displayType ?? ''} hero={hero ?? undefined} />
  }

  return null
}

export default Hero
